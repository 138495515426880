<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              female
            </v-icon>
            Gender
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Gender
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Audience Section -->
            <v-col cols="12">
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    female
                  </v-icon>

                  Gender
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipText }}
                  </span>
                </v-tooltip>
              </div>

              <v-radio-group
                v-model="form"
                @change="submitForm"
                hide-details
              >
                <v-radio
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="index"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "gender"

export default {
  name: "FilterGender",

  data: () => ({
    form: null,

    shouldShowModal: false,

    options: [
      {
        label: "Any",
        value: null,
      },
      {
        label: "Male",
        value: "male",
      },
      {
        label: "Female",
        value: "female",
      }
    ],

    tooltipText: "Identify influencers by gender. We determine gender of influencers by analyzing profile photos, name and text in profile bio and selfies in recent posts."
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    appliedFilter() {
      return this.$store.getters["campaignPlanning/findFilterByType"](filterName)
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilter)
    }
  },

  methods: {
    /**
     * Takes an item and generates the text
     */
    computeFilterText({ label }) {
      return `Gender: ${label}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     */
    async submitForm() {
      const selectedItem = this.options[this.form]

      // if there's some value, then update the filter
      if (selectedItem.value) {
        this.$store.dispatch("campaignPlanning/replaceFilter", {
          type: filterName,
          data: {
            color: "pink lighten-5",
            icon: "female",
            iconColor: "pink",
            text: this.computeFilterText({ label: selectedItem.label }),
            inputs: {
              ...selectedItem,
              weight: this.form.audienceWeight
            }
          }
        })
      }
      // otherwise remove the filter
      else {
        // if some other filter has been applied
        if (this.isFilterApplied) {
          this.$store.dispatch("campaignPlanning/removeFilter", this.appliedFilter.id)
        }
      }
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "campaignPlanning" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = 0
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "campaignPlanning" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      const index = this.options.findIndex((search) => search.value === e.detail.item.data.inputs.value)
      this.form = index
    })
  }
}
</script>
